import React from 'react';
import { motion } from 'framer-motion';
import { Icon } from '@iconify/react';
import logo from '../../assets/logo.svg';
import arrow from '../../assets/Arrow---Down-2.svg';

const NavItem = ({ href, text }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className="flex items-center gap-2 justify-between mb-8"
  >
    {text} <img src={arrow} className="h-[10px]" alt={`${text} arrow`} />
  </a>
);

const MobileNavbar = ({ isOpen, toggleNavbar }) => {
  return (
    <motion.nav
      className={`fixed top-0 z-30 right-0 h-screen w-full bg-[#25346A] text-white transform p-6 ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } transition-transform duration-300 ease-in-out`}
    >
      <div className="flex items-center justify-between">
        <a href="/">
          <img src={logo} alt="logo" className="h-[33px]" />
        </a>
        <Icon
          onClick={toggleNavbar}
          icon="carbon:close-filled"
          className="text-white text-[25px] cursor-pointer"
        />
      </div>
      <div className="mt-14">
        <NavItem href="https://wicrypt.com/product.html" text="Products" />
        <NavItem href="https://wicrypt.com/business.html" text="Services" />
        <NavItem
          text="Technology"
          href="https://wicrypt.com/roadmap-2021.html"
        />
        <NavItem href="https://wicrypt.com/faq.html" text="Support" />
        <a href="https://medium.com/wicrypt" target="_blank" rel="noreferrer">
          Blog
        </a>
        <div className="mt-14">
          <a
            href="https://dashboard.wicrypt.com/"
            target="_blank"
            rel="noreferrer"
            className="text-[#E5B910] py-2 px-8 border-[1.5px] font-semibold rounded-[25px] border-[#E5B910]"
          >
            Sign up for free
          </a>
        </div>
      </div>
    </motion.nav>
  );
};

export default MobileNavbar;
