import React from 'react';
import image1 from '../assets/image1.png';
import image11 from '../assets/image11.png';
import image2 from '../assets/image2.png';
import image22 from '../assets/image22.png';
import image3 from '../assets/image3.png';
import image33 from '../assets/image33.png';
import Fade from 'react-reveal/Fade';
import MobileImageSlider from './common/MobileImageSlider';

const BusinessUnlockComponent = () => {
  return (
    <div className="lg:mt-10 mt-7 lg:p-0">
      <MobileImageSlider />
      <Fade cascade>
        <div className="lg:grid md:grid lg:grid-cols-3 md:grid-cols-3 gap-6 hidden overflow-x-scroll  no-scrollbar">
          <img
            src={image1}
            alt="image1"
            className=" dark:hidden flex rounded-tl-[20px] rounded-bl-[20px]"
          />
          <img
            src={image11}
            alt="image1"
            className="dark:flex hidden rounded-tl-[20px] rounded-bl-[20px]"
          />
          <img src={image2} alt="image2" className="dark:hidden flex" />
          <img src={image22} alt="image22" className="dark:flex hidden " />
          <img
            src={image3}
            alt="image3"
            className="dark:hidden flex rounded-tr-[20px] rounded-br-[20px]  "
          />{' '}
          <img
            src={image33}
            alt="image33"
            className="dark:flex hidden  rounded-tr-[20px] rounded-br-[20px]  "
          />
        </div>{' '}
      </Fade>

      <div className="text-white lg:text-[40px] md:text-[40px] text-[23px] lg:leading-[60px]  md:leading-[60px] leading-[35px] font-semibold my-8">
        <p>Unlock Your Business Potential: </p>
        <p>
          Earn Up to <span className="text-[#E5B910]"> $100 Weekly!</span>
        </p>
      </div>

      <div className="flex gap-3 items-center lg:pb-20 pb-12">
        {/* <input
          className=" placeholder:text-[#C4C4C4] rounded-[30px] focus:outline-none  px-4 py-3 lg:text-base text-sm lg:w-[35%] w-[60%]"
          placeholder="Sample@gmail.com"
        /> */}
        <a
          href="https://shop.wicrypt.com/?ref=WNT"
          target="_blank"
          rel="noreferrer"
          className="bg-[#E5B910] text-white text-center rounded-[30px] lg:px-12 md:px-12 px-4 lg:w-fit md:w-fit w-full lg:text-base text-sm py-3 "
        >
          Buy Now
        </a>
      </div>
    </div>
  );
};

export default BusinessUnlockComponent;
