import React from 'react';

const BusinessElevationSection = () => {
  return (
    <div className="bg-bg3-pattern dark:bg-bg3-pattern2 md:mx-10 xl:mx-[7.5rem] mx-5 lg:my-16 my-12 text-[#25346A] dark:text-white lg:bg-cover lg:bg-bottom bg-left-bottom lg:p-10 p-5 font-semibold rounded-[20px] border-2 border-[#25346A1A] h-[450px]">
      <p className="lg:text-[25px] md:text-[25px] text-[22px] mb-5 leading-8">
        Elevate your business game with Wicrypt!
      </p>

      <p className="lg:text-[45px] md:text-[45px] text-[30px] leading-[50px] lg:w-[70%] md:w-[70%] mb-10 lg:leading-[62px] md:leading-[62px]">
        Join <span className="text-[#E5B910]">50,000+ Users</span> <br />
        Connected Globally
      </p>

      <a
        href="https://wicrypt.com/product.html"
        className="bg-[#E5B910] text-white rounded-[30px] flex justify-center lg:w-fit md:w-fit w-full lg:px-8 md:px-8 px-4 lg:text-base text-sm py-3"
      >
        Buy Now
      </a>
    </div>
  );
};

export default BusinessElevationSection;
