import React from 'react';
import Navbar from './components/common/Navbar';
import BusinessUnlockComponent from './components/BusinessUnlockComponent';
import CustomCard from './components/common/CustomCard';
import CustomerReviews from './components/CustomerReviews';
import Benefits from './components/Benefits';
import BusinessElevationSection from './components/BusinessElevationSection';
import Clients from './components/Clients';
import Footer from './components/common/Footer';

const App = () => {
  return (
    <div className="dark:bg-[#141519]">
      <div className="bg-bg-pattern dark:bg-bg-pattern2 ">
        <div className=" p-5  md:px-10 xl:px-[7.5rem]">
          <div className="container mx-auto">
            <Navbar />
            <BusinessUnlockComponent />
          </div>
        </div>
      </div>

      <CustomCard>
        Revolutionize your small business by captivating customers, boosting
        revenue, collecting valuable feedback, and promoting your services
        through our innovative hotspot connectivity. Discover the ultimate
        solution tailored for your small business success.
      </CustomCard>

      <Benefits />

      <div className="lg:p-0 p-5 md:mx-10 xl:mx-[7.5rem]">
        <div className="mx-auto container ">
          <CustomerReviews />
          <Clients />
        </div>
      </div>

      <CustomCard>
        Embark on a journey with us to drive global peer-to-peer internet
        access, all while building tangible assets and providing real-world
        solutions.
      </CustomCard>

      <BusinessElevationSection />

      <Footer />
    </div>
  );
};

export default App;
