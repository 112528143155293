import React, { useState } from 'react';
import MobileNavbar from './MobileNavbar';
import logo from '../../assets/logo.svg';
import useDarkTheme from '../../components/common/useDarkTheme';
import arrow from '../../assets/Arrow---Down-2.svg';
import { Icon } from '@iconify/react';

const NavItem = ({ href, text }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className="flex items-center gap-2 hover:text-[#E5B910]"
  >
    {text} <img src={arrow} className="h-[10px]" alt={`${text} arrow`} />
  </a>
);

const Navbar = () => {
  const [colorTheme, setTheme] = useDarkTheme();

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <>
      <div className="flex items-center justify-between  text-white">
        <div className="lg:flex hidden items-center justify-between w-full py-7">
          <a href="/">
            <img src={logo} alt="logo" className="h-[33px]" />
          </a>
          <div className="flex gap-x-10 text-sm items-center">
            <NavItem href="https://wicrypt.com/product.html" text="Products" />
            <NavItem href="https://wicrypt.com/business.html" text="Services" />
            <NavItem
              text="Technology"
              href="https://wicrypt.com/roadmap-2021.html"
            />
            <NavItem href="https://wicrypt.com/faq.html" text="Support" />
            <a
              href="https://medium.com/wicrypt"
              target="_blank"
              rel="noreferrer"
              className="hover:text-[#E5B910]"
            >
              Blog
            </a>
            <a
              href="https://dashboard.wicrypt.com/"
              target="_blank"
              rel="noreferrer"
              className="text-[#E5B910] hover:bg-[#E5B910] hover:text-[#fff] py-2 px-4 border-[1.5px] font-semibold rounded-[25px] border-[#E5B910]"
            >
              Sign up for free
            </a>

            {colorTheme === 'light' ? (
              <Icon
                icon="ion:moon-sharp"
                className="text-white text-[30px] cursor-pointer"
                onClick={toggleTheme}
              />
            ) : (
              <Icon
                icon="heroicons-solid:sun"
                className="text-white text-[30px] cursor-pointer"
                onClick={toggleTheme}
              />
            )}
          </div>
        </div>

        <div className="lg:hidden flex items-center justify-between w-full pb-5 pt-2">
          <a href="/">
            <img src={logo} alt="logo" className="h-[33px]" />
          </a>
          <div className="flex gap-4 items-center">
            {colorTheme === 'light' ? (
              <Icon
                icon="ion:moon-sharp"
                className="text-white text-[25px] cursor-pointer"
                onClick={toggleTheme}
              />
            ) : (
              <Icon
                icon="heroicons-solid:sun"
                className="text-white text-[25px] cursor-pointer"
                onClick={toggleTheme}
              />
            )}

            <Icon
              icon="charm:menu-hamburger"
              className="text-white text-[30px] cursor-pointer"
              onClick={toggleNavbar}
            />
          </div>
        </div>
      </div>

      <MobileNavbar toggleNavbar={toggleNavbar} isOpen={isOpen} />
    </>
  );
};

export default Navbar;
