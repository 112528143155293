import React from 'react';
import title from '../assets/title1.svg';
import title2 from '../assets/title11.svg';
import avatar1 from '../assets/Valor.jpg';
import avatar2 from '../assets/Olabisi.jpg';
import avatar3 from '../assets/Kagan.jpg';
import Fade from 'react-reveal/Fade';

const TestimonialCard = ({ avatar, name, platform, testimonial }) => (
  <div className="boxShadow dark:boxShadow2 dark:bg-[#2B2F36] px-5 py-6 rounded-[14px] h-full">
    <img src={avatar} alt={name} className="w-[60px h-[60px] rounded-full" />
    <p className="text-[#25346A] dark:text-white font-semibold mb-3 mt-4">
      {name}
    </p>
    <a
      href={platform}
      target="_blank"
      rel="noreferrer"
      className="text-sm hover:underline text-[#1D1D1D] dark:text-white font-medium"
    >
      Instagram
    </a>
    <p className="text-[#6B6B6B] dark:text-[#C4C4C4] text-sm mt-4 leading-6">
      {testimonial}
    </p>
  </div>
);

const CustomerReviews = () => {
  return (
    <div className="mx-auto container lg:my-24 my-10">
      <img src={title} alt="title" className="flex dark:hidden" />
      <img src={title2} alt="title2" className="hidden dark:flex" />
      <p className="text-[#6B6B6B] dark:text-[#C4C4C4]">
        Don&apos;t just take our word for it - hear it from our satisfied
        customers!
      </p>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 mt-10">
        <Fade bottom>
          <TestimonialCard
            avatar={avatar1}
            name="Valor"
            platform="https://www.instagram.com/valorreviews?igsh=NWZvMDg2cDAwbWs2"
            testimonial="Discovering Wicrypt felt like stumbling on a hidden gem. The prospect of offsetting my data subscription initially sparked my interest, but what truly captivated me was its potential to earn me money while I browsed. Within my first month, I accumulated around $60—more than enough to offset my entire internet subscription. That's when I knew Wicrypt wasn't just another platform. I'm truly hooked on the possibilities it presents."
          />
          <TestimonialCard
            avatar={avatar2}
            name="Olabisi"
            platform="https://www.instagram.com/the_olamobisi?igsh=N2Vtd24ybTZjN285&utm_source=qr"
            testimonial="I run a small wellness store in the heart of Ibadan, Oyo state and the Wicrypt device has been a game changer. My customers don't just come to purchase, they stay hours to use the internet and explore other products. Because they use the internet, I earn rewards and mine the WNT too. Offsetting my subscription bills. It's really been a plus to my business in more ways than I can express"
          />
          <TestimonialCard
            avatar={avatar3}
            name="Kagan"
            platform="https://www.instagram.com/kagantech?igsh=MXJkYnliNnY0cm9tMg=="
            testimonial="Passive income is an interesting thing, and I've never found an easier way to make passive income than simply setting up Wicrypt devices and forgetting about it. You simply use your internet connection as you normally would and get paid without having to do anything else. It's perfect!!!
            "
          />
        </Fade>
      </div>{' '}
    </div>
  );
};

export default CustomerReviews;
