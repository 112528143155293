import coinMarketCap from '../../assets/coinMarketCap.svg';
import coinGecko from '../../assets/coinGecko.svg';
import coinMarketCap2 from '../../assets/coinMarketCap2.svg';
import coinGecko2 from '../../assets/coinGecko2.svg';

const MoreStatsIcons = () => {
  return (
    <div className="grid gap-20px">
      <h6 className="text-sm font-semibold text-[#25346A] dark:text-white">
        More Stats
      </h6>
      <div className="flex items-center gap-4">
        <a
          href="https://coinmarketcap.com/currencies/wicrypt/"
          className={`transition ease-in-out duration-500 hover:-translate-y-1 hover:scale-110 px-1 rounded-md`}
        >
          <img
            src={coinMarketCap}
            alt="Coin Market Cap"
            className="h-[32px] flex dark:hidden"
          />
          <img
            src={coinMarketCap2}
            alt="Coin Market Cap"
            className="h-[42px] dark:flex hidden"
          />
        </a>
        <a
          href="https://www.coingecko.com/en/coins/wicrypt"
          className={`transition ease-in-out duration-500 hover:-translate-y-1 hover:scale-110 px-1 rounded-md`}
        >
          <img
            src={coinGecko}
            alt="Coin Gecko"
            className="h-[32px]  flex dark:hidden"
          />
          <img
            src={coinGecko2}
            alt="Coin Gecko"
            className="h-[32px]  dark:flex hidden"
          />
        </a>
      </div>
    </div>
  );
};

export default MoreStatsIcons;
