import React from 'react';

const VerticalTimeline = ({ data }) => {
  return (
    <div className="flex flex-col">
      {data.map((item, index) => (
        <div key={index} className="relative flex">
          <div className="flex">
            <div className="bg-[#25346A] dark:bg-[#E5B910] h-[40px] w-[40px] z-10 flex items-center justify-center text-white rounded-full">
              {index + 1}
            </div>
            {index < data.length - 1 && (
              <div className="bg-[#E5B910] dark:bg-[#E5B910] dark:opacity-50 h-full w-[3px] absolute left-[18px] top-2"></div>
            )}
          </div>
          <div className="lg:ml-10 ml-4 -top-1 mb-5 relative">
            {item?.children}
          </div>
        </div>
      ))}
    </div>
  );
};

export default VerticalTimeline;
