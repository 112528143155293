import React from 'react';
import title from '../assets/title2.svg';
import title2 from '../assets/title22.svg';
import kfc from '../assets/kfc.svg';
import logo2 from '../assets/logo2.svg';
import logo3 from '../assets/logo3.svg';
import logo4 from '../assets/logo4.svg';
import logo5 from '../assets/logo5.svg';
import logo6 from '../assets/logo6.svg';
import logo7 from '../assets/logo7.svg';
import Fade from 'react-reveal/Fade';

const Clients = () => {
  return (
    <div className="mx-auto container lg:my-24 mt-10 mb-4">
      <img src={title} alt="title" className="mb-8 flex dark:hidden" />
      <img src={title2} alt="title2" className="mb-8 hidden dark:flex" />

      <div className="grid grid-cols-2 lg:grid-cols-7  md:grid-cols-5 justify-around border lg:p-5 p-3 border-[rgba(47, 128, 237, 0.1)] dark:border-[#2F80ED1A] rounded-[20px] flex-wrap gap-3">
        <Fade right>
          <img src={kfc} alt="kfc" />
          <img src={logo2} alt="logo2" />
          <img src={logo3} alt="logo3" />
          <img src={logo4} alt="logo4" />
          <img src={logo5} alt="logo5" />
          <img src={logo6} alt="logo6" />
          <img src={logo7} alt="logo7" />
        </Fade>
      </div>
    </div>
  );
};

export default Clients;
