import React, { useState, useEffect } from 'react';
import image1 from '../../assets/image1.png';
import image2 from '../../assets/image2.png';
import image3 from '../../assets/image3.png';
import image11 from '../../assets/image11.png';
import image22 from '../../assets/image22.png';
import image33 from '../../assets/image33.png';
import Fade from 'react-reveal/Fade';

const MobileImageSlider = () => {
  const images = [
    { light: image1, dark: image11 },
    { light: image2, dark: image22 },
    { light: image3, dark: image33 },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  return (
    <Fade cascade>
      <div className="lg:hidden md:hidden gap-6 flex overflow-x-scroll no-scrollbar">
        {images.map((image, index) => (
          <div
            key={index}
            className={index === currentIndex ? 'block' : 'hidden'}
          >
            <img
              src={image.light}
              alt={`slide${index + 1}`}
              className="rounded-[20px] dark:hidden block"
            />
            <img
              src={image.dark}
              alt={`slide${index + 1}`}
              className="rounded-[20px] dark:block hidden"
            />
          </div>
        ))}
      </div>
    </Fade>
  );
};

export default MobileImageSlider;
