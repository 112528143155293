import React from 'react';
import VerticalTimeline from './common/VerticalTimeline';
import title from '../assets/title3.svg';
import title2 from '../assets/title33.svg';

const Benefits = () => {
  const createBenefit = (title, description) => ({
    children: (
      <div className="flex lg:gap-10 gap-2 lg:mb-6 mb-1">
        <div>
          <h2 className="text-[18px] font-semibold mb-3 mt-2 text-[#1D1D1D] dark:text-[#FFFFFF]">
            {title}
          </h2>
          <p className="text-[#6B6B6B] text-sm leading-[22px] font-medium dark:text-[#C4C4C4]">
            {description}
          </p>
        </div>
      </div>
    ),
  });

  const allBenefits = [
    createBenefit(
      'Browsing Speed',
      'Wicrypt OS improves browsing speed and reduce bandwidth used in browsing especially in remote areas with poor internet service'
    ),
    createBenefit(
      'Real-time Location',
      'Wicrypt OS supports real-time location for each device'
    ),
    createBenefit(
      'Customised Landing Page',
      'Wicrypt OS supports customised landing pages and syncs seamlessly with the web dashboard'
    ),
    createBenefit(
      'Multi-Client',
      'Wicrypt OS supports multiple clients and handles concurrency much better than the previous version.'
    ),
    createBenefit(
      'VPN Capabilities',
      'Wicrypt OS supports VPN capabilities to ensure privacy when surfing the web'
    ),
    createBenefit(
      'Parental Control',
      'No more setting up individual devices. You can set up parental control at the internet source with Wicrypt.'
    ),
    createBenefit(
      'Advertising System',
      'Our system allows device owners to turn regular everyday routers or MiFis into revenue-generating digital billboards.'
    ),
    createBenefit(
      'WNT Mining',
      'Wicrypt OS allows mining of WNT (Wicrypt Network Tokens) to happen seamlessly on the device'
    ),
  ];

  return (
    <div className="bg-[#2F80ED0D] dark:bg-[#0B0E11] p-5 md:px-10 xl:px-[7.5rem] ">
      <div className="container mx-auto lg:px-0 lg:py-20 py-10 ">
        <img
          src={title}
          alt="title"
          className="lg:mb-14 mb-10 dark:hidden flex"
        />
        <img
          src={title2}
          alt="title"
          className="lg:mb-14 mb-10 dark:flex hidden"
        />

        <div className="grid lg:grid-cols-2 gap-10 ">
          <VerticalTimeline data={allBenefits} />
          <div class="parallax lg:block hidden"></div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
