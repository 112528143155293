import { useEffect, useState } from 'react';

export default function useDarkTheme() {
  const storedTheme = localStorage.theme;
  const [theme, setTheme] = useState(storedTheme || 'light');
  const colorTheme = theme === 'dark' ? 'dark' : 'light';

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('dark', 'light');
    root.classList.add(theme);

    // save theme to local storage
    localStorage.setItem('theme', theme);
  }, [theme]);

  return [colorTheme, setTheme];
}
