export const footerLinksData = [
  {
    heading: 'Product',
    links: [
      {
        text: 'Hotspot Hub',
        link: 'https://wicrypt.com/product.html',
      },
      {
        text: 'Staking',
        link: 'https://stake.wicrypt.com/',
      },
      {
        text: 'Explorer',
        link: 'https://stake.wicrypt.com/',
      },
    ],
  },
  //   {
  //     heading: 'Technology',
  //     links: [
  //       {
  //         text: 'Documentation',
  //         link: '#',
  //       },
  //       {
  //         text: 'API',
  //         link: '#',
  //       },
  //       {
  //         text: 'Developer',
  //         link: '#',
  //       },
  //       {
  //         text: 'Token',
  //         link: '#',
  //       },
  //       {
  //         text: 'Roadmap',
  //         link: '#',
  //       },
  //     ],
  //   },
  {
    heading: 'Resources',
    links: [
      {
        text: 'Whitpaper',
        link: 'http://whitepaper.wicrypt.com/',
      },
      {
        text: 'FAQs',
        link: 'https://wicrypt.com/faq.html',
      },
      {
        text: 'Blog',
        link: 'https://medium.com/wicrypt',
      },
      //   {
      //       text: "Media Kits",
      //       link: "https://drive.google.com/drive/folders/1SmjSVNkhX0mwGu9j5lIenUEGg3J_nn0A"
      //   }
    ],
  },
];
