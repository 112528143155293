import React from 'react';

const FooterLinkGroup = ({ header, linksData }) => {
  return (
    <div className="grid gap-4">
      <h3 className="text-lg text-[#25346A] dark:text-white font-medium">
        {header}
      </h3>
      <ul className="w-max grid gap-4">
        {linksData.map((item, idx) => (
          <li key={`flink-${idx}`}>
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              className="text-xs text-[#6B6B6B] dark:text-[#C4C4C4] transition duration-500 ease-in-out font-medium  hover:scale-105"
            >
              {item.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterLinkGroup;
