import React from 'react';
import logo from '../../assets/logo-blue.svg';
import logo2 from '../../assets/logo.svg';
// import WNTWhiteIcon from '../../assets/Wicrypt_Token_Coin_White_b 1.svg';
import mexc from '../../assets/mexc.svg';
import quickswap from '../../assets/quickswap.svg';
import SocialIcons from './SocialIcons';
import MoreStatsIcons from './MoreStatsIcons';
// import { Icon } from '@iconify/react';
import FooterLinkGroup from './FooterLinkGroup';
import { footerLinksData } from './footerdata';

const Footer = () => {
  return (
    <footer className="footer bg-white dark:bg-[#141519] pb-4 pt-7 px-5 md:px-10 xl:px-[7.5rem]">
      <div className="container mx-auto">
        <section className="flex flex-col lg:flex-row justify-between gap-y-11">
          <div>
            <a href={'/'}>
              <img src={logo} alt="Wicrypt" className="flex dark:hidden" />
              <img src={logo2} alt="Wicrypt" className="dark:flex hidden" />
            </a>
            <p className="text-lg text-[#343A40] dark:text-[#fff]  font-medium mt-10">
              Join Our Community
            </p>
            <SocialIcons />
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:flex md:gap-x-12 lg:gap-x-16 xl:gap-x-[120px] items-baseline gap-y-7 md:gap-y-12">
            {footerLinksData.map((item, idx) => (
              <FooterLinkGroup
                key={`flgrp-${idx}`}
                header={item.heading}
                linksData={item.links}
              />
            ))}
            <div className="grid gap-4">
              <h4 className="text-lg text-[#25346A] dark:text-white font-medium">
                Contact Us:
              </h4>
              <div className="grid gap-4 md:w-[172px] ">
                {[
                  {
                    label: 'Address 1',
                    value: '5C Bethel Plaza Garden Avenue, Enugu, Nigeria.',
                  },
                  {
                    label: 'Address 2',
                    value:
                      '204A Isale Eko Avenue Dolphin Estate Lagos, Nigeria.',
                  },
                  {
                    label: 'Address 3',
                    value: '8 The Green, Ste A, Dover, DE 19901, United States',
                  },
                  {
                    label: 'Email',
                    value: (
                      <a
                        href={'mailto:hello@wicrypt.com'}
                        className="text-xs text-[#6B6B6B] dark:text-[#C4C4C4] font-medium"
                      >
                        hello@wicrypt.com
                      </a>
                    ),
                  },
                ].map(({ label, value }, index) => (
                  <span key={index} className="grid gap-2">
                    <h5 className="text-sm text-[#1D1D1D] dark:text-white  font-medium">
                      {label}:
                    </h5>
                    <p className="text-xs text-[#6B6B6B] dark:text-[#C4C4C4]  font-medium">
                      {value}
                    </p>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col-reverse md:flex-row justify-between mt-3 pt-14 md:pt-0 gap-9 md:gap-0">
          <div className="flex flex-col lg:flex-row justify-between gap-10">
            {/* <div className="flex flex-col md:flex-row items-baseline gap-8 lg:gap-0 xl:gap-16 xl:w-full">
              {[1, 2].map((_, index) => (
                <div key={index} className="grid gap-10px">
                  <div className="flex items-center relative -left-[1.7rem] md:left-0">
                    <img src={WNTWhiteIcon} alt="Wicrypt Token coin" />
                    <div className="grid gap-[7px]">
                      <p className="text-xs text-[#000000DE] dark:text-white font-semibold">
                        Token Price
                      </p>
                      <p className="text-20px text-[#1D1D1D] dark:text-white font-semibold leading-[25.524px]">
                        0.060404 USD
                      </p>
                      <span className="flex items-center gap-1">
                        <Icon
                          icon="heroicons:chevron-double-up-16-solid"
                          className="text-[20px] p-1 rounded bg-[#5FBE91] text-white "
                        />
                        <p className="text-10px text-[#5FBE91] font-normal tracking-[0.63px]">
                          0.92%
                        </p>
                      </span>
                    </div>
                  </div>
                  <a
                    href={
                      index === 0
                        ? 'https://coinmarketcap.com/?utm_medium=widget&utm_campaign=cmcwidget&utm_source=wicrypt.com&utm_content=wicrypt'
                        : 'https://www.coingecko.com/en?utm_source=wicrypt.com&utm_medium=coin_price_chart_widget&utm_content=wicrypt'
                    }
                    className="text-xs font-medium text-[#25346A] md:text-center transition ease-in-out mt-4 duration-500 hover:scale-110 hover:text-[#25346A] dark:text-white dark:hover:text-white"
                  >
                    Powered by {index === 0 ? 'CoinMarketCap' : 'CoinGecko'}
                  </a>
                </div>
              ))}
            </div> */}
            <div className="grid gap-8">
              <MoreStatsIcons />
              <div className="grid gap-20px">
                <h6 className="text-sm font-semibold text-[#000000DE] dark:text-white">
                  Available on
                </h6>
                <div className="flex gap-12">
                  {[
                    { imgSrc: mexc, label: 'MEXC' },
                    { imgSrc: quickswap, label: 'Quickswap' },
                  ].map(({ imgSrc, label }, index) => (
                    <a
                      key={index}
                      href="/"
                      className="flex items-center gap-2 transition ease-in-out duration-500 hover:scale-110"
                    >
                      <img src={imgSrc} alt="" />
                      <p className="text-sm font-semibold text-[#6B6B6B] dark:text-[#C4C4C4]">
                        {label}
                      </p>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="grid gap-20px md:self-end">
            <p className="text-sm text-[#000000DE] font-semibold w-[212px] dark:text-white ">
              Subscribe to Wicrypt newsletter
            </p>
            <a
              href="https://wicrypt.substack.com/"
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center flex-shrink-0 rounded-full w-fit text-sm font-semibold text-white leading-[22px] py-2 px-4 bg-[#CFA809] transition ease-in-out duration-500 hover:-translate-y-1 hover:scale-110 mt-4"
            >
              Subscribe Now
            </a>
          </div>
        </section>
        <section className="mx-auto flex flex-col-reverse lg:flex-row justify-between items-center mt-12 md:mt-[76px]">
          <p className="text-xs text-[#C6C6C6] font-medium text-center">
            Copyright © 2018-{new Date().getFullYear()}. All rights reserved.
            Wicrypt Limited
          </p>
          <ul className="flex justify-between self-start md:self-center items-center gap-[10px] mb-8 md:mb-0">
            {[
              {
                label: 'Privacy Policy',
                link: 'https://wicrypt.com/privacy-policy.html',
              },
              {
                label: 'Terms and Conditions',
                link: 'https://wicrypt.com/terms-condition.html',
              },
            ].map((item, index) => (
              <li key={index}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-xs text-[#242424] hover:underline dark:text-[#C4C4C4] font-normal transition duration-500 ease-in-out hover:text-secondary hover:scale-105"
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
